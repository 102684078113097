<template>
    <div class="wrapper_main">
        <main class="content">
            <div class="content__header">
                <div class="content__header-left">
                    <h1 class="heading-page-h1">{{ $translate('products') }}</h1>
                </div>
                <div class="content__header-right">
					<BluePlusButton to="/products/add">
                        {{ $translate('add-a-product') }}
                    </BluePlusButton>
                </div>
            </div>
            <TableAdvanced 
                v-model="sortCol" 
                :rows="filteredRowsWithoutDoubles" 
                :columns="columns" 
                @resetFilters="resetFilters" 
                :isCheckedAll="isCheckedAll"
                :checkedInputs="checkedInputs"
                @toggleAllCheckedInputs="toggleAllCheckedInputs"
                class="products_page_table"
            >
                <template v-slot:filters>
                    <div class="filter-options__search" style="max-width: 412px">
                        <input type="search" class="input bg-left search-icon" :placeholder="$translate('search-by-name,-id,-rfid,-serial#...')" 
                                v-model="rowsFilters.search"/>
                    </div>
                    <div class="filter-options__items flex-x-wrap flex-auto">
                        <DropdownAdvanced 
                            :options="categoriesOptions" 
                            v-model="rowsFilters.categoryID" 
                            selectAll="Category"
                        />
                        <DropdownAdvanced 
                            :options="deviceOptions" 
                            v-model="rowsFilters.deviceID" 
                            selectAll="Device"
                        />
                        <DropdownAdvanced 
                            selectAll="Status"
                            :options="productsStatusOptions" 
                            v-model="rowsFilters.status" 
                        />
                        <!-- <DatePicker v-model="rowsFilters.manufacturing_date" /> -->
                    </div>
                </template>
                <template v-slot:row="slotData">
                    <td>
                        <label class="module__check">
                            <input type="checkbox" :checked="checkedInputs[slotData.r.id]" @change="setCheckedInputs($event,slotData.r.id)"/>
                            <span class="check"></span>
                            <span class="long-text-wrapper" >
                                <router-link :to="`/products/${slotData.r.id}`" class="text long-text-stroke" style="text-decoration: underline;">
                                    {{ slotData.r.title }}
                                </router-link>
                            </span>
                        </label>
                    </td>
                    <td>
                        {{ slotData.r.categoryName || " "}}
                    </td>
                    <!-- <td>
                        {{ slotData.r.title }}
                    </td> -->
                    <td>
                        {{ slotData.r.parentProduct||" " }}
                    </td>
                    <td>
                        {{ slotData.r.serial_number||" " }}
                    </td>
                    <td>
                        {{ slotData.r.locationName||" " }}
                    </td>
                    <!-- <td>{{slotData.r.deviceName}}</td> -->
                    <td style="white-space: nowrap;">
                        <span class="table-statys enable"><span class="dot"></span> {{ slotData.r.status }}</span>
                    </td>
                    <td>
                        {{ slotData.r.manufacturing_date || " "}}
                    </td>
                    <td style="padding-right: 50px">
                        {{ slotData.r.expiry_date || " "}}
                    </td>
                    <td class="position-stiky">
                        <div class="table-options-drop dropdown-wrapper">
                            <button
                                type="button" class="btn btn-sm btn-transp dropdown-btn" 
                                :class="{'active':menuOpen.open === slotData.r.id }"
                                @click="openOptions($event, slotData.r.id)"
                            >
                                <inline-svg :src="require('@/assets/img/dots-menu.svg')" class="pointer-events-none" />
                            </button>
                            <div 
                                class="categories__dropdown dropdown-content" 
                                :class="{'active':menuOpen.open === slotData.r.id }"
                                style="position: fixed;"
                                :style="{ left: menuOpen.left, top: menuOpen.top, bottom: 'auto' }"
                            >
                                <ul>
                                    <!-- <li>
                                        <a href="#">+ Add to Dispatch</a>
                                    </li> -->
                                    <li>
                                        <router-link :to="`/products/${slotData.r.id}`">{{ $translate('view-product') }}</router-link>
                                    </li>
                                    <li class="list-line"></li>
                                    <li>
                                        <button type="button" class="btn red-color" data-target="delete-item-2" @click="confirmDelete(slotData.r.id,'Delete Product?')">{{ $translate('delete-product') }}</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </td>
                </template>
            </TableAdvanced>
            <TableMessages
                :rows="rows"
                :filters="rowsFilters"
                :title="$translate('no-products-yet!')"               
                :searchTitle="$translate('no-products-corresponding-to-the-criteria')"
                to="/products/add"
                :addTitle="$translate('add-product')"
            ></TableMessages>
            <!-- 
                before translate
                title="No products yet!"               
                searchTitle="No Products Corresponding To The Criteria"
                addTitle="Add Product"
             -->
        </main>
        <Pagination v-model="filteredRows" :items="rawSource" />
     
    </div>
</template>

<script>
import Base from "@/components/base";
import Buttons from "@/components/buttons";
import Pupops from "@/components/popups";

import { API } from "aws-amplify";
import {deleteProduct} from "@/graphql/mutations";


import { mixTables,mixDeleteItem } from "@/mixins";
export default {
    name: "Products",
    mixins: [mixTables,mixDeleteItem],
    components: {
        ...Base,
		...Buttons,
        ...Pupops,
        
    },
    data() {
        return {
            source: "products",
            columns: [
                // { name: "Product ID", filter: "id" },
                { name: "Product Name", filter: "title" },
                { name: "Category", filter: "categoryName" },
                { name: "Parent Product", filter: "parentProduct" },
                { name: "Serial", filter: "serial_number" },

                { name: "Location", filter: "locationName" },
                // { name: "Device", filter: "deviceName" },
                { name: "Status", filter: "status" },
                { name: "Manufacturing Date", filter: "manufacturing_date" },
                { name: "Expiry Date", filter: "expiry_date" },
                { name: "" },
            ],
            searchField:"title",
            //config delete item
            deleteQueryType:"deleteProduct",
            calculatedFields:[
                {
                    filter: 'deviceID',
                    path: ['devices','items'],
                    calcFunction: (arr)=>arr[0]?arr[0].id:null
                },
                {
                    filter: 'deviceName',
                    path: ['devices','items'],
                    calcFunction: (arr)=>arr[0]?arr[0].name:" "
                },
                {
                    filter: 'categoryID',
                    path: ['category','items'],
                    calcFunction: (productCategories)=>{          
                        return this.categories.find((c)=>{
                            return c.id==(productCategories[0] && productCategories[0].categoryID)
                        })?.id||null
                    }
                },
                {
                    filter: 'categoryName',
                    path: ['category','items'],
                    calcFunction: (productCategories)=>{        
                        return this.categories.find((c)=>{
                            return c.id==(productCategories[0] && productCategories[0].categoryID)
                        })?.name||" "
                    }
                },
                {
                    filter: 'categoryName__test',
                    path: [],
                    calcFunction: (product)=>{
                        product.category.items.forEach(item=>{
                            let errProduct = this.categories.find((c)=>{
                                return c.id==(item.categoryID)
                            })
                            if(!errProduct && this.categories && this.categories.length ){
                                console.log('err product', product.title, item, product, errProduct, this.categories)
                            }
                        })
                    }
                },
                {
                    filter: 'parentProduct',
                    path: ['parent_products'],
                    calcFunction: (parent_products)=>{
                        if (parent_products&&parent_products[0]) {
                            return this.products.find(p=>{
                                return p.id==parent_products[0].id
                            })?.title||""
                        }     
                        return ' '
                    }
                },
                {
                    filter: 'locationName',
                    path: ['location'],
                    calcFunction: (location)=>{
                        return location.address
                    }
                }
            ],
            menuOpen: {
                left: '-500px',
                top: 0,
                open: null,
            } 
        };
    },
    computed: {
        sourceData(){
            // return this.$store.state.products.filter(product=>{
            //     // product
            //     return true
            // })
            return [...this.$store.state.products,...this.$store.state.receivedProducts]
        },
        companyData() {
            return this.$store.state.companyData || {};
        },
        products() {
            return this.$store.state.products || [];
        },  
        categories(){
            return this.$store.state.categories || [];
        },
        categoriesOptions(){
            return this.$store.state.categories.filter(c=>{
                return c.products.items.length>0
            })
        },
        deviceOptions(){
            let devices=[]
            this.$store.state.products.forEach(product=>{
                if(product.devices.items.length){
                    devices.push({id:product.devices.items[0].id,name:product.devices.items[0].name})
                }
            })
            return devices
            
        },
        productsStatusOptions(){
            return [{ id: 'active', name: 'Active' },
                    { id: 'disabled', name: 'Disabled' }]
        },
        filteredRowsWithoutDoubles(){
            let filteredData = [];
            for (let index = 0; index < this.filteredRows.length; index++) {
                if(!filteredData?.map(item => item?.id)?.includes(this.filteredRows[index]?.id)){
                    filteredData.push(this.filteredRows[index])
                }                
            }

            return filteredData;
        }
    },
    methods: {
        openOptions(ev, id) {
            if(this.menuOpen.open === id){
                this.menuOpen.open = null;
                this.menuOpen = {
                    left: '-500px',
                    top: 0,
                    open: null,
                }
            } else {
                this.menuOpen.open = id;
                setTimeout(() => {
                    let targetClientRect = ev.target.getBoundingClientRect();

                    this.menuOpen.left = (targetClientRect.x - ev.target.nextElementSibling.offsetWidth + 22) + 'px';

                    if((targetClientRect.top + targetClientRect.height + ev.target.nextElementSibling.offsetHeight+70) > document.querySelector('#app').offsetHeight){
                        this.menuOpen.top = ((targetClientRect.y - ev.target.nextElementSibling.offsetHeight) - 10) + 'px';
                    } else {
                        this.menuOpen.top = (targetClientRect.y + 15) + 'px';
                    }
                }, 10);
            }
        },
        outsideMenuClick(e){
            if(this.menuOpen.open&&!e.target.closest('.table-options-drop.dropdown-wrapper')){
                this.menuOpen.open = null;
            }
        },
        async additionalDeleteActions(id){
            let product=this.products.find(o=>{
                return o.id===id  
            })
            if(product){
                for(let relation of product.category.items){
                    await this.deleteQuery("deleteProductsCategory", relation.id)
                }
            }
            
        },


    },
    async created() {
        // TODO: listProducts

        await this.$store.dispatch("GET_PRODUCTS");
        let self = this;
        window.addEventListener('click', self.outsideMenuClick);
    },
    beforeDestroy(){
        let self = this;
        window.removeEventListener('click', self.outsideMenuClick);
    }
};
</script>

<style></style>
